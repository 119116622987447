var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nominal Pokok")]),_c('money',{staticClass:"text-start p-3",staticStyle:{"max-width":"220px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"30px"},model:{value:(_vm.dataForm.request.nominalLoan),callback:function ($$v) {_vm.$set(_vm.dataForm.request, "nominalLoan", $$v)},expression:"dataForm.request.nominalLoan"}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rate")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticStyle:{"width":"220px"},attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0] ? 'Kolom Ini Wajib Di Isi' : ''}},[_c('b-select',{staticStyle:{"width":"100%"},attrs:{"expanded":"","size":"is-small","options":_vm.options.rate},on:{"input":_vm.clearInputAndTableResponse},model:{value:(_vm.dataForm.request.loanMarginCalculateId),callback:function ($$v) {_vm.$set(_vm.dataForm.request, "loanMarginCalculateId", $$v)},expression:"dataForm.request.loanMarginCalculateId"}},_vm._l((_vm.options.rate),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Jangka Waktu")]),_c('b-field',{staticStyle:{"max-width":"220px","width":"100%"}},[_c('b-input',{staticStyle:{"width":"100%"},attrs:{"size":"is-small"},model:{value:(_vm.dataForm.request.loanPeriod),callback:function ($$v) {_vm.$set(_vm.dataForm.request, "loanPeriod", _vm._n($$v))},expression:"dataForm.request.loanPeriod"}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",staticStyle:{"font-size":"12px"}},[_vm._v("Bulan")])])],1)],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rate Flat ( % ) ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticStyle:{"width":"220px"},attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0] ? 'Kolom Ini Wajib Di Isi' : ''}},[_c('b-input',{staticStyle:{"width":"100%"},attrs:{"size":"is-small"},model:{value:(_vm.dataForm.request.rate),callback:function ($$v) {_vm.$set(_vm.dataForm.request, "rate", _vm._n($$v))},expression:"dataForm.request.rate"}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s("Per Tahun"))])])],1)]}}],null,true)})],1),_c('div',{staticClass:"flex justify-start items-center mt-5"},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.loanInstallmentSimulationProccess.isLoading,"type":"is-success","disabled":_vm.property.animation.loanInstallmentSimulationProccess.isLoading ===
            true},on:{"click":function($event){return handleSubmit(_vm.proccesLoanInstallmentSimulation)}}},[_vm._v(" Hitung Simulasi ")])],1),_c('div',{staticClass:"flex mt-5 flex-row justify-end w-full"},[_c('div',{staticClass:"w-full flex flex-row justify-between",staticStyle:{"max-width":"300px"}},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-link","icon-right":"file-pdf","loading":_vm.property.animation.isDownloadingFile,"disabled":_vm.property.animation.isDownloadingFile},on:{"click":function($event){return _vm.onDownloadFile('pdf')}}},[_vm._v(" Download PDF")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-link","icon-right":"microsoft-excel","loading":_vm.property.animation.isDownloadingFile,"disabled":_vm.property.animation.isDownloadingFile},on:{"click":function($event){return _vm.onDownloadFile('xls')}}},[_vm._v(" Download Excel")])],1)]),_c('hr',{staticClass:"w-full my-5",staticStyle:{"background-color":"grey","height":"1px"}}),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nominal Pokok")]),_c('b-input',{staticStyle:{"max-width":"220px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"30px"},attrs:{"size":"is-small","value":_vm.dataForm.response.nominalLoan
            ? _vm.convertDecimalCurrency(_vm.dataForm.response.nominalLoan)
            : _vm.dataForm.response.nominalLoan,"disabled":""}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Jangka Waktu")]),_c('b-field',{staticStyle:{"max-width":"220px","width":"100%"}},[_c('b-input',{staticStyle:{"width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.dataForm.response.totalPeriod),callback:function ($$v) {_vm.$set(_vm.dataForm.response, "totalPeriod", $$v)},expression:"dataForm.response.totalPeriod"}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",staticStyle:{"font-size":"12px"}},[_vm._v("Bulan")])])],1)],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Total Rate ( % )")]),_c('b-input',{staticStyle:{"max-width":"220px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"30px"},attrs:{"disabled":"","size":"is-small"},model:{value:(_vm.dataForm.response.totalRate),callback:function ($$v) {_vm.$set(_vm.dataForm.response, "totalRate", $$v)},expression:"dataForm.response.totalRate"}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Total Margin")]),_c('b-input',{staticStyle:{"max-width":"220px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"30px"},attrs:{"size":"is-small","value":_vm.dataForm.response.totalMargin
            ? _vm.convertDecimalCurrency(_vm.dataForm.response.totalMargin)
            : _vm.dataForm.response.totalMargin,"disabled":""}})],1),(_vm.dataForm.request.loanMarginCalculateId === '2')?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rate Efektif (%)")]),_c('b-input',{staticStyle:{"max-width":"220px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"30px"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.dataForm.response.effectiveRate),callback:function ($$v) {_vm.$set(_vm.dataForm.response, "effectiveRate", $$v)},expression:"dataForm.response.effectiveRate"}})],1):_vm._e(),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.loanInstallmentSimulation,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.loanInstallmentSimulation.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
              _vm.property.listElement.loanInstallmentSimulation.downloading ===
                false
            )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.loanInstallmentSimulation.message)+" ")]):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"no","label":"No"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.index + 1))])]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"principalRemain","label":"Sisa Pokok"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.principalRemain ? _vm.convertDecimalCurrency(props.row.principalRemain) : props.row.principalRemain))])]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"principal","label":"Angsuran Pokok"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.principal ? _vm.convertDecimalCurrency(props.row.principal) : props.row.principal))])]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"margin","label":"Angsuran Margin"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.margin ? _vm.convertDecimalCurrency(props.row.margin) : props.row.margin))])]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"installment","label":"Total Angsuran"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.installment ? _vm.convertDecimalCurrency(props.row.installment) : props.row.installment))])]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }