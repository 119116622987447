import "jspdf-autotable";

export default {
  name: "TLoanInstallmentSimulation",
  data() {
    return {
      property: {
        animation: {
          loanInstallmentSimulationProccess: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        listElement: {
          loanInstallmentSimulation: {
            message: "",
            downloading: false,
          },
        },
      },
      dataForm: {
        request: {
          nominalLoan: 0,
          rate: 0,
          loanMarginCalculateId: "",
          loanPeriod: 0,
        },
        response: {
          nominalLoan: 0,
          totalPeriod: 0,
          totalRate: 0,
          totalMargin: 0,
          totalInstallment: 0,
          rateFlat: 0,
          rateEffective: 0,
          effectiveRate: 0,
        },
      },
      options: {
        rate: [],
      },
      table: {
        header: {
          loanInstallmentSimulation: [
            {
              key: "no",
              label: "No",
            },
            {
              key: "principalRemain",
              label: "Sisa Pokok",
            },
            {
              key: "principal",
              label: "Angsuran Pokok",
            },
            {
              key: "margin",
              label: "Angsuran Margin",
            },
            {
              key: "installment",
              label: "Total Angsuran",
            },
          ],
        },
        data: {
          loanInstallmentSimulation: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        nominalLoan: this.dataForm.request.nominalLoan,
        rate: this.dataForm.request.rate,
        loanMarginCalculateId: this.dataForm.request.loanMarginCalculateId,
        loanPeriod: this.dataForm.request.loanPeriod,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/simulation/download",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Simulasi_Angsuran_${
          this.dataForm.request.loanMarginCalculateI
        }.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    clearInputAndTableResponse() {
      this.dataForm.response.nominalLoan = 0;
      this.dataForm.response.totalPeriod = 0;
      this.dataForm.response.totalInstallment = 0;
      this.dataForm.response.totalRate = 0;
      this.dataForm.response.totalMargin = 0;
      this.dataForm.response.rateFlat = 0;
      this.dataForm.response.rateEffective = 0;
      this.table.data.loanInstallmentSimulation = [];
    },
    getReferenceLoanMarginCalculate() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-margin-calculate",
        })
        .then((resp) => {
          resp.data.data.map((index) => {
            const value = index.loanMarginCalculateId;
            const text = index.loanMarginCalculateName;
            this.options.rate.push({ text, value });
          });
          // this.options.rate.push({ text: "Efektif Anuitas", value: "anuity" });
        });
    },

    async proccesLoanInstallmentSimulation() {
      this.clearInputAndTableResponse();
      const payload = {
        nominalLoan: this.dataForm.request.nominalLoan,
        rate: this.dataForm.request.rate,
        loanMarginCalculateId:
          this.dataForm.request.loanMarginCalculateId === "anuity"
            ? null
            : this.dataForm.request.loanMarginCalculateId,
        loanPeriod: this.dataForm.request.loanPeriod,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.loanInstallmentSimulationProccess.isLoading = true;
        this.property.listElement.loanInstallmentSimulation.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "loan",
            reqUrl:
              this.dataForm.request.loanMarginCalculateId === "anuity"
                ? "loan-installment/anuity-simulation"
                : "loan-installment/simulation",
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.dataForm.response.totalPeriod = resp.data.data.totalPeriod;
              this.dataForm.response.totalMargin = resp.data.data.totalMargin;
              this.dataForm.response.nominalLoan = resp.data.data.nominalLoan;
              this.dataForm.response.effectiveRate =
                resp.data.data.effectiveRate;
              this.dataForm.response.totalInstallment =
                this.dataForm.request.loanMarginCalculateId === "anuity"
                  ? resp.data.data.totalInstallment
                  : 0;
              this.table.data.loanInstallmentSimulation =
                resp.data.data.detailInstallment;
              this.dataForm.response.totalRate =
                (this.dataForm.request.rate / 12) *
                this.dataForm.request.loanPeriod;
              if (this.dataForm.request.loanMarginCalculateId === "1") {
                this.dataForm.response.rateFlat =
                  this.dataForm.request.rate * 12;
                this.dataForm.response.rateEffective =
                  resp.data.data.rateEffective;
              } else if (this.dataForm.request.loanMarginCalculateId === "2") {
                this.dataForm.response.rateEffective =
                  this.dataForm.request.rate * 12;
              }
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, 1500);
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.animation.loanInstallmentSimulationProccess.isLoading = false;
            this.property.listElement.loanInstallmentSimulation.downloading = false;
          }, 1500);
        }
      }
    },
    appendDefaultMessageTable() {
      this.property.listElement.loanInstallmentSimulation.message =
        "Belum Ada Data Yang Di Proses";
    },
  },
  mounted() {
    this.getReferenceLoanMarginCalculate();
    this.appendDefaultMessageTable();
  },
};
